<template>
	<div>
		<div v-if="showMultiDownloadWarning" class="header-notes">
			<p>
				<span style="font-weight: bold;">For spots played from January 1st 2025, the exported RFIN and Audology files will have Radio Type = AM/FM/DAB Only for standard Commercial Spots for the
					SMBAP metro FM/AM stations as ARN commences splitting inventory between AM/FM and Streaming.</span><br/>
				There are three downloadable file types on this page.<br/>
				1. Download Summary: Excel file including sheets for Played Spots, Unplayed Spots, Summary, and the legacy ERAM copy/paste sheet.<br/>
				2. RFIN: CSV file containing spots for GFK markets ONLY which can be imported into Audology, ERAM or Probe with the CRA standard RFIN format to run R&F.
				For ERAM use Import > R360 Schedule option.
				For Audology use the R&F > Gold Standard CSV Upload option.<br/>
				3. Audology CSV: CSV file containing spots for ALL surveyed markets (GFK & Xtra) which can be imported into Audology to run R&F.
				In Audology choose the R&F > Post Campaign Aquira CSV Upload option.
			</p>
			<span class="closer" @click="showMultiDownloadWarning = false"><i class="mdi mdi-close"></i></span>
		</div>
		<div>
			<div style="padding-top: 10px; display: flex; align-items: flex-end; flex-wrap: wrap;">
				<div style="width: 120px; margin-right: 20px;">
					<p class="above-select-label">Lookup Type</p>
					<select class="select-styled" v-model="lookupType">
						<option value="contractId">Contract ID</option>
						<option value="advertiser">Advertiser</option>
					</select>
				</div>
				<div v-if="lookupType === 'contractId'" style="width: 200px; margin-right: 20px">
					<p class="above-select-label">
						Contract ID
						<label style="font-size: 12px; margin-left: 10px;"
							title="Automatically merge in adjusted contract versions ie a, b contract IDs?">
							<input type="checkbox" v-model="contractMergeVersions" style="margin-left: 10px;" />
							Merge Versions
						</label>
					</p>
					<input type="text" class="text-field-input" style="padding: 10px 7px 10px; font-size: 16px;" v-model="contractLookupText"
						placeholder="Contract IDs (comma separated)"
					/>
				</div>
				<div v-if="lookupType === 'advertiser'" style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Advertiser</p>
					<AutocompleterStringOnly v-if="advertisers && advertisers.length > 0" :searchlist="advertisers" :incomingval="advertiserLookupText"
						idfldname="id" textfldname="name" v-on:update="updateChosenAdvertiser($event)"
					></AutocompleterStringOnly>
				</div>
				<div style="margin-right: 20px; width: 140px;">
					<p class="above-select-label">Start Date</p>
					<date-picker class="dater-tr" style="width: 140px;" v-model:value="startdate" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
				<div style="margin-right: 20px; width: 140px;">
					<p class="above-select-label">End Date</p>
					<date-picker class="dater-tr" style="width: 140px;" v-model:value="enddate" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
<!--				<div style="display: flex; flex-wrap: wrap; align-items: flex-end;">-->
<!--					<div class="netshortcut" @click="showStationSelectOverlay = true">Stations</div>-->
<!--				</div>-->
				<div style="margin-right: 10px; margin-top: 10px;"><span class="primary-btn" @click="fetchData(false)">View</span></div>
				<div style="margin-right: 10px; margin-top: 10px;"><span class="primary-btn" @click="fetchData(true)">Download</span></div>
				<div style="margin-right: 10px; margin-top: 10px;"><span class="primary-btn" @click="fetchData(false, 'rfin')">RFIN CSV (GFK mkts)</span></div>
				<div style="margin-right: 10px; margin-top: 10px;"><span class="primary-btn" @click="fetchData(false, 'audology')">Audology CSV (all mkts)</span></div>

				<div v-if="showStationSelectOverlay" class="loader-overlay">
					<div class="stationselect-popup whitebg">
						<div class="popup-topbar">
							<span class="boldme">Choose Stations</span>
							<span class="closer" @click="showStationSelectOverlay = false"><i class="mdi mdi-close"></i></span>
						</div>
						<div style="width: 100%; text-align: left;" class="popup-insides">
							<p class="boldme" style="margin: 0 0 10px 0;">ARN Aquira Database</p>
							<div v-for="st in stationsMetro" :key="st.name" style="display: inline-block; text-align: left; width: 250px; margin-bottom: 8px;">
								<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.name" :value="st.name" />
								<label style="margin-right: 15px;" :for="'stn'+st.name">{{st.name}}</label>
							</div>

<!--							<p class="boldme" style="margin: 20px 0 10px 0;">ARN Regional Aquira Database</p>-->
<!--							<div v-for="st in stationsReg" :key="st.name" style="display: inline-block; text-align: left; width: 250px; margin-bottom: 8px;">-->
<!--								<input style="margin-right: 5px;" type="checkbox" v-model="chosenStations" :id="'stn'+st.name" :value="st.name" />-->
<!--								<label style="margin-right: 15px;" :for="'stn'+st.name">{{st.name}}</label>-->
<!--							</div>-->
						</div>
					</div>
				</div>

			</div>

			<div style="margin-top: 10px;">
				<div class="station-head-text" @click="showStationSelectOverlay = true">Stations (click to select/filter)</div>
				<div v-if="chosenStations.length === 0 || chosenStations.length === stations.length" style="font-size: 12px;"><span>Currently including all stations</span></div>
				<div v-else>
					<div v-for="st in chosenStations" class="stnItem" :key="st" @click="removeSelectedStation(st)">{{st}} <i class="mdi mdi-close"></i></div>
				</div>
				<div style="margin-top: 10px; display: flex; gap: 5px; align-items: center; font-size: 12px;">
					<input type="checkbox" v-model="useLocalRatecard" />
					<span>Use Local Regional Ratecard. Only check this if you're in a regional market.</span>
				</div>
			</div>

			<div style="margin-top: 20px; ">
				<div style="border-top: 1px solid #DDD; border-bottom: 1px solid #DDD; display: flex; width: 100%;">
					<div class="tabber" @click="tabview = 'loghealth'" :class="{active : tabview === 'loghealth'}">Log Health</div>
					<div class="tabber" @click="tabview = 'externalloads'" :class="{active : tabview === 'externalloads'}">Externals</div>
					<div class="tabber" @click="tabview = 'summary'" :class="{active : tabview === 'summary'}">Summary</div>
					<div class="tabber" @click="tabview = 'spotplays'" :class="{active : tabview === 'spotplays'}">Plays</div>
					<div class="tabber" @click="tabview = 'spotsunplayed'" :class="{active : tabview === 'spotsunplayed'}">Not Played</div>
				</div>
				<div v-if="!isFetching && tabview === 'loghealth'" style="padding: 20px 0;">
					<div v-if="missingDays.rundate === null" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<p class="bold">Log Health Checked on {{missingDays.rundate}}<br /></p>
						<div v-if="missingDays.missing.length === 0">No missing logs in past 35 days</div>
						<div v-else>
							<p style="margin: 10px 0;">The following logs have potential issues (checking past 35 days excluding yesterday which is yet to be reconciled).</p>
							<p v-for="(log, index) in missingDays.missing" :key="index">{{log}}</p>
						</div>
					</div>
				</div>
				<div v-if="!isFetching && tabview === 'externalloads'" style="padding: 20px 0;">
					<div v-if="externalLoads.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<p style="margin: 10px 0;">Last 50 load events from external stations/networks.</p>
						<p v-for="(item, index) in externalLoads" :key="index">{{item.load_date}}: {{item.stations}} month of {{item.load_month}}, {{item.nspots}} spot rows</p>
					</div>
				</div>
				<div v-if="!isFetching && tabview === 'spotplays'" style="padding: 20px 0;">
					<div v-if="spotplays.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<ALTable :cols="cols" :rowdata="spotplays" :pagination="50"></ALTable>
					</div>
				</div>
				<div v-if="!isFetching && tabview === 'spotsunplayed'" style="padding: 20px 0;">
					<div v-if="spotsNotPlayed.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<ALTable :cols="colsUnplayed" :rowdata="spotsNotPlayed" :pagination="50"></ALTable>
					</div>
				</div>
				<div v-if="!isFetching && tabview === 'summary'" style="padding: 20px 0;">
					<div v-if="summaryData.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<div style="margin-bottom: 20px;">
							<p><span class="bold">Contract IDs: </span>{{summaryInfo.contract_id}}</p>
							<p><span class="bold">Campaign/s: </span>{{summaryInfo.campaign_name}}</p>
							<p><span class="bold">Total Played Spots: </span>{{numdisplay(summaryInfo.total_spots)}}</p>
							<p><span class="bold">Total Paid: </span>${{numdisplay(summaryInfo.total_paid)}}</p>
							<p><span class="bold">Total Value: </span>${{numdisplay(summaryInfo.total_value)}}</p>
							<p><span class="bold">Total Added Value: </span>${{numdisplay(summaryInfo.total_added_value)}}</p>
							<p><span class="bold">Number of Unplayed Spots: </span>{{numdisplay(numSpotsNotPlayed)}}</p>
							<p><span class="bold">Spots Played + Unplayed: </span>{{numdisplay(numSpotsNotPlayed + summaryInfo.total_spots)}}</p>
						</div>
						<ALTable :cols="summaryCols" :rowdata="summaryData" :pagination="50"></ALTable>
					</div>
				</div>
				<div v-if="isFetching" style="padding: 20px 0; height: 300px; width: 500px;">
					<ChartLoaderInsert style="z-index: 9;"></ChartLoaderInsert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import AutocompleterStringOnly from "@/components/AutocompleterStringOnly";
import ALTable from "@/components/ALTable";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "PostCampaignReporting",
	components: {
		ChartLoaderInsert,
		ALTable,
		AutocompleterStringOnly,
		DatePicker,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			showStationSelectOverlay: false,

			tabview: 'loghealth', //summary or spotplays
			isFetching: false,

			lookupType: 'contractId',
			contractLookupText: '',
			contractMergeVersions: true, //should we merge a, b etc versions
			advertiserLookupText: '',
			startdate: null,
			enddate: null,
			chosenStations: [],
			useLocalRatecard: false,

			advertisers: [],
			stations: [],
			missingDays: {
				missing: [],
				rundate: null,
			},
			externalLoads: [],
			spotplays: [],
			spotsNotPlayed: [],
			numSpotsNotPlayed: null,
			summaryData: [],
			summaryInfo: {
				campaign_name: '',
				client_name: '',
				contract_id: '',
				timing: '',
				total_added_value: 0,
				total_paid: 0,
				total_spots: 0,
				total_value: 0,
			},

			cols: [
				{title: 'Aired Date', sortable: true, fldid: 'aired_date'},
				{title: 'Aired Time', sortable: true, fldid: 'aired_time'},
				{title: 'Station', sortable: true, fldid: 'station_name'},
				{title: 'Contract ID', sortable: true, fldid: 'contract_id'},
				{title: 'Advertiser', sortable: true, fldid: 'advertiser_name'},
				{title: 'Salesperson', sortable: true, fldid: 'salesperson_name'},
				{title: 'Booked Daypart', sortable: true, fldid: 'booked_daypart'},
				{title: 'Aired Daypart', sortable: true, fldid: 'aired_daypart'},
				{title: 'Spot Duration', sortable: true, fldid: 'duration_booked'},
				{title: 'Key Number', sortable: true, fldid: 'keynumber'},
				{title: 'PIB Aired', sortable: true, fldid: 'pib_aired'},
				{title: 'Rate Paid', sortable: true, fldid: 'rate_paid'},
				{title: 'Aquira Ratecard Rate', sortable: true, fldid: 'aq_ratecard_rate'},
				{title: 'Daypart Rate', sortable: true, fldid: 'lif_ratecard_rate'},
			],

			colsUnplayed: [
				{title: 'Booked Date', sortable: true, fldid: 'booked_date'},
				{title: 'Station', sortable: true, fldid: 'station_name'},
				{title: 'Contract ID', sortable: true, fldid: 'contract_id'},
				{title: 'Advertiser', sortable: true, fldid: 'advertiser_name'},
				{title: 'Salesperson', sortable: true, fldid: 'salesperson_name'},
				{title: 'Booked Daypart', sortable: true, fldid: 'booked_daypart'},
				{title: 'Spot Duration', sortable: true, fldid: 'duration_booked'},
				{title: 'Key Number', sortable: true, fldid: 'keynumber'},
				{title: 'Rate Paid', sortable: true, fldid: 'rate_paid'},
			],

			summaryCols: [
				{title: 'State', sortable: false, fldid: 'state_name'},
				{title: 'Market', sortable: false, fldid: 'mkt_name'},
				{title: 'Station', sortable: false, fldid: 'stn_name'},
				{title: 'Type', sortable: false, fldid: 'booked_daypart'},
				{title: 'Duration', sortable: false, fldid: 'duration'},
				{title: 'Delivered', sortable: false, fldid: 'nspots', numtype: 'number'},
				{title: 'Campaign Total', sortable: false, fldid: 'tot_paid', numtype: 'dollars'},
				{title: 'Campaign Value', sortable: false, fldid: 'tot_value', numtype: 'dollars'},
				{title: 'Added Value', sortable: false, fldid: 'added_value', numtype: 'dollars'},
			],

			showMultiDownloadWarning: true,
		}
	},
	computed: {
		lif_field() {
			if(this.useLocalRatecard === true) return 'lif_ratecard_rate_local'
			else return 'lif_ratecard_rate'
		},
		stationsMetro() {
			return this.stations.filter(item => item.aqdb === 'arnmetro')
		},
		stationsReg() {
			return this.stations.filter(item => item.aqdb === 'arnreg')
		},
	},
	methods: {
		updateChosenAdvertiser(ev) {
			if(ev.name.length > 0) this.advertiserLookupText = ev.name
		},
		removeSelectedStation(st) {
			this.chosenStations = this.chosenStations.filter(item => item !== st)
		},
		getMetaLists() {
			let self = this;
			let targ = "/api/post-campaign/get-meta-lists.php";
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else if(ret.advertisers) {
					self.advertisers = ret.advertisers
					self.stations = ret.stations
					self.missingDays = ret.missing_days
					self.externalLoads = ret.external_loads
				}
				self.isFetching = false
			}).catch(function (error) {
				console.log(error);
				self.isFetching = false
			});
		},
		fetchData(doDownload = false, dltype = false) {
			let self = this;
			let targ = "/api/post-campaign/fetch-spotplays.php";
			this.showStationSelectOverlay = false
			this.isFetching = true
			axios.post(targ, JSON.stringify({
				type: self.lookupType,
				advertiser: self.advertiserLookupText,
				contractid: self.contractLookupText,
				contractMergeVersions: self.contractMergeVersions,
				startdate: self.startdate,
				enddate: self.enddate,
				stations: self.chosenStations,
				download: doDownload,
				dltype: dltype, //'rfin' for CSV export
				useLocalRatecard: self.useLocalRatecard,
			})).then(function (response) {
				let ret = response.data; //response.data is returned info
				if (ret.spotplays) self.spotplays = ret.spotplays
				else self.spotplays = []
				if(ret.unplayedSpots) self.spotsNotPlayed = ret.unplayedSpots
				else self.spotsNotPlayed = []
				self.numSpotsNotPlayed = 0
				if(ret.numUnplayedSpots) self.numSpotsNotPlayed = ret.numUnplayedSpots
				if(ret.information) self.summaryInfo = ret.information
				if(ret.summary) self.summaryData = ret.summary
				else self.summaryData = []
				self.isFetching = false
				if(ret.filepath) {
					window.open(ret.filepath, '_blank')
				}
				if(self.tabview === 'loghealth') self.tabview = 'summary'
				// if(ret.freq_request) {
				// 	let f = ret.freq_request
				// 	let t = 'https://yourwavelength.com/central/post/freq/rr-do-rf-vue?vuexcel=1&'
				// 	t += 'userid='+f.userid+"&useremail="+f.useremail+"&usertoken="+f.usertoken+"&dltoken="
				// 	t += f.dltoken+"&dltime="+f.dltime+"&incomingFilename="+f.incomingFilename
				// 	window.open(t, '_blank')
				// }
			}).catch(function () {
				self.isFetching = false
			});
		},
	},
	watch: {
		useLocalRatecard() {
			let myfld = 'lif_ratecard_rate'
			if(this.useLocalRatecard === true) {
				myfld = 'lif_ratecard_rate_local'
			}
			let c = this.cols.findIndex(item => item.title === 'Daypart Rate')
			if(c > -1) this.cols[c].fldid = myfld
		}
	},
	mounted() {
		document.title = 'Post Campaign Reporting'
		this.getMetaLists()
		let d = new Date()
		this.enddate = d.getFullYear()+'-'+this.ldzero(d.getMonth()+1)+"-"+this.ldzero(d.getDate())
		let mt = d.getMonth()
		let yr = d.getFullYear()
		if(mt === 0) {
			mt = 12
			yr--
		}
		this.startdate = yr+'-'+this.ldzero(mt)+"-01"
	}
}
</script>

<style scoped>
.netshortcut {
	display: inline-block;
	border: 1px solid #DDD;
	background: #EAEAEA;
	cursor: pointer;
	transition: background-color 0.2s ease-in-out;
	margin: 0 20px 0 0;
	padding: 12px 10px;
	border-radius: 5px;
}
.netshortcut:hover {
	background-color: #DDD;
}
.stationselect-popup {
	width: 70%;
	height: 700px;
	margin: 50px auto;
	position: relative;
}
.stationselect-popup.whitebg {
	background-color: #FFF;
	overflow-y: auto;
}
.popup-topbar {
	position: absolute;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: rgb(239, 239, 239);
	border-bottom: 1px solid rgb(204, 204, 204);
	color: #000;
	padding: 0 20px;
	width: 100%;
	height: 40px;
}
.closer {
	font-size: 18px;
	cursor: pointer;
}
.closer:hover i {
	color: #888;
}
.popup-insides {
	width: 100%;
	padding: 20px;
	height: calc(100% - 40px);
	margin-top: 40px;
	overflow-y: auto;
}
.stnItem {
	padding: 5px;
	border-radius: 3px;
	border: 1px solid #DDD;
	font-size: 12px;
	display: inline-block;
	margin: 5px;
	background-color: #FFF;
}
.station-head-text {
	margin: 20px 0 10px;
	cursor: pointer;
}
.station-head-text:hover {
	text-decoration: underline;
}
.stnItem i {
	cursor: pointer;
}
.stnItem i:hover {
	color: #C00;
}
.tabber {
	background-color: #FFF;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	padding: 10px 15px;
	font-weight: 500;
	font-size: 13px;
}
.tabber:hover {
	background-color: #DDD;
}
.tabber.active {
	background-color: #CCC;
}
.header-notes {
	margin: 0 0 10px;
	padding: 10px;
	border: 1px solid #DDD;
	background-color: #DDD;
	color: #444;
	font-size: 12px;
	display: flex;
	flex-grow: 1;
	justify-content: space-between;
}
.header-notes .closer {
	justify-self: flex-end;
	cursor: pointer;
	padding: 0;
	margin-left: 15px;
	align-self: flex-start;
}
.header-notes .closer:hover {
	color: #888;
}
</style>