<template>
	<div>
		<PermissionChecker :allowedperms="viewPerms"></PermissionChecker>
		<div v-if="userHasPermission(viewPerms)">

			<div>
				<div style="width: 300px; display: flex; align-items: center;">
					<div>
						<p class="above-select-label">Select station</p>
						<select class="select-styled" v-model="selectedStation">
							<option v-for="opt in stationOptions" :key="opt.name" :value="opt.name">{{opt.title}}</option>
						</select>
					</div>
				</div>
			</div>

			<p class="section-heading">
				GFK Ratings
				<router-link to="/gfk-ratings/charts">
					<i class="mdi mdi-open-in-new"></i>
				</router-link>
			</p>
			<div class="chart-rows">
				<ChartHolderDash charttitle="10+ Share" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[0]" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="10+ Cume" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[1]" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash charttitle="10+ TSL" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[2]" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash :charttitle="selectedStationDemoTargetName+' Share'" :height="220" source="GFK Metro Radio Ratings" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="gfkData[3]" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>

<!--			<p class="section-heading" v-if="selectedStation !== 'cruise1323'">-->
<!--				Quarterly Brand Tracking-->
<!--				<router-link to="/brand-tracking/market-dashboard">-->
<!--					<i class="mdi mdi-open-in-new"></i>-->
<!--				</router-link>-->
<!--			</p>-->
<!--			<div class="chart-rows" v-if="selectedStation !== 'cruise1323'">-->
<!--				<ChartHolderDash-->
<!--						charttitle="Reported Listening %" :height="220"-->
<!--						:source="chartsSource.reportedlistening" :addclasses="'quarter'">-->
<!--					<template v-slot:default><ApexLineTrend :chartdata="apexdata.reportedlistening" :fetching="getChartsBtnWorking"></ApexLineTrend></template>-->
<!--				</ChartHolderDash>-->
<!--				<ChartHolderDash-->
<!--						charttitle="Reported Top 3 Station %" :height="220"-->
<!--						:source="chartsSource.top3stn" :addclasses="'quarter'">-->
<!--					<template v-slot:default><ApexLineTrend :chartdata="apexdata.top3stn" :fetching="getChartsBtnWorking"></ApexLineTrend></template>-->
<!--				</ChartHolderDash>-->
<!--				<ChartHolderDash-->
<!--						charttitle="Unaided Station Awareness %" :height="220"-->
<!--						:source="chartsSource.station_unaided_awareness" :addclasses="'quarter'">-->
<!--					<template v-slot:default><ApexLineTrend :chartdata="apexdata.station_unaided_awareness" :fetching="getChartsBtnWorking"></ApexLineTrend></template>-->
<!--				</ChartHolderDash>-->
<!--				<ChartHolderDash-->
<!--						charttitle="Aided Station Awareness %" :height="220"-->
<!--						:source="chartsSource.station_aided_awareness" :addclasses="'quarter'">-->
<!--					<template v-slot:default><ApexLineTrend :chartdata="apexdata.station_aided_awareness" :fetching="getChartsBtnWorking"></ApexLineTrend></template>-->
<!--				</ChartHolderDash>-->
<!--&lt;!&ndash;				<ChartHolderDash&ndash;&gt;-->
<!--&lt;!&ndash;						charttitle="NPS / Likely Recommend" :height="220"&ndash;&gt;-->
<!--&lt;!&ndash;						:source="chartsSource.likely_recommend" :addclasses="'quarter'">&ndash;&gt;-->
<!--&lt;!&ndash;					<template v-slot:default><ApexLineTrend :chartdata="apexdata.likely_recommend" :fetching="getChartsBtnWorking"></ApexLineTrend></template>&ndash;&gt;-->
<!--&lt;!&ndash;				</ChartHolderDash>&ndash;&gt;-->
<!--			</div>-->

			<p class="section-heading">
				Monthly Streaming
				<router-link to="/streaming/trending">
					<i class="mdi mdi-open-in-new"></i>
				</router-link>
				& Podcast On-Demand
				<router-link to="/podcasts/on-demand-radio">
					<i class="mdi mdi-open-in-new"></i>
				</router-link>
			</p>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Streaming Total Hours" :height="220"
						:source="streamChartsSource.streamstntrnd" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexData.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Streaming Monthly Unique Listeners" :height="220"
						:source="streamChartsSource.streamstntrnd" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="streamApexDataUniques.streamstntrnd" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						v-if="selectedStationObject && selectedStationObject.podshowid > 0"
						charttitle="Podcasts - Breakfast Show Downloads" :height="220"
						:source="chartsSource.meg_show" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="apexdata.meg_show" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						v-if="selectedStationObject && selectedStationObject.podshowid > 0"
						charttitle="Podcasts - Breakfast Show Unique Listeners" :height="220"
						:source="chartsSource.meg_show_uniques" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="apexdata.meg_show_uniques" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>

			<p class="section-heading">
				Web & Social
				<router-link to="/web/chart-explorer">
					<i class="mdi mdi-open-in-new"></i>
				</router-link>
			</p>
			<div class="chart-rows">
				<ChartHolderDash
						charttitle="Website Weekly Users" :height="220"
						source="Google Analytics" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="webusersweeklydata" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Website Weekly Sessions" :height="220"
						source="Google Analytics" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="websessweeklydata" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash
						charttitle="Facebook Weekly Impressions" :height="220"
						source="Sprout - Facebook Insights API" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="fbimprweeklydata" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
				<ChartHolderDash v-if="instaimprweeklydata && instaimprweeklydata.length > 0"
						charttitle="Instagram Weekly Impressions" :height="220"
						source="Sprout - Facebook/Instagram Insights" :addclasses="'quarter'">
					<template v-slot:default><ApexLineTrend :chartdata="instaimprweeklydata" :fetching="getChartsBtnWorking"></ApexLineTrend></template>
				</ChartHolderDash>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import ChartHolderDash from "@/components/ChartHolderDash";
import axios from "axios";
import ApexLineTrend from "@/components/charts/ApexLineTrend";
import PermissionChecker from "@/components/PermissionChecker";
export default {
	name: "BrandHealthDash",
	components: {
		PermissionChecker,
		ApexLineTrend,
		ChartHolderDash
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			viewPerms: ['elt','content-metro','connections'],
			stationOptions: [
				{name: 'kiis1065', title: 'KIIS 1065', dsgid: 1, podshowid: 1, streamstnid: 'KIIS 106.5',
					gfkstnid: 'g_201009', gfktargdemoname: 'F25-39', gfktargdemoid: 118, gfkmktid: 110,
					// fbid: 203586689826154, instaid: 17841401203287888,
					fbid: 5621853, instaid: 5621870,
				},
				{name: 'wsfm', title: 'GOLD101.7', dsgid: 1, podshowid: 5, streamstnid: 'WSFM',
					gfkstnid: 'g_2207', gfktargdemoname: '40-54', gfktargdemoid: 66, gfkmktid: 110,
					// fbid: 282833978501, instaid: 17841401298777302,
					fbid: 5621852, instaid: 5621875,
				},
				{name: 'kiis1011', title: 'KIIS 1011', dsgid: 2, podshowid: 2, streamstnid: 'KIIS 101.1',
					gfkstnid: 'g_301056', gfktargdemoname: 'F25-39', gfktargdemoid: 118, gfkmktid: 111,
					// fbid: 745235618859731, instaid: 17841401447094794,
					fbid: 5621856, instaid: 5621865,
				},
				{name: 'gold1043', title: 'Gold 104.3', dsgid: 2, podshowid: 3, streamstnid: 'GOLD 1043',
					gfkstnid: 'g_3207', gfktargdemoname: '40-54', gfktargdemoid: 66, gfkmktid: 111,
					// fbid: 119721054728044, instaid: 17841400920370442,
					fbid: 5621854, instaid: 5621866,
				},
				{name: '973fm', title: 'KIIS 973', dsgid: 3, podshowid: 8, streamstnid: '973FM',
					gfkstnid: 'g_7203', gfktargdemoname: 'F30-44', gfktargdemoid: 2316, gfkmktid: 112,
					// fbid: 121655557857406, instaid: 17841400746811607,
					fbid: 5621862, instaid: 5621867,
				},
				// {name: '4kq', title: '4KQ', dsgid: 3, podshowid: 10, streamstnid: 7,
				// 	gfkstnid: 85, gfktargdemoname: '45-59', gfktargdemoid: 8, gfkmktid: 3,
				// 	fbid: 119694911401078, instaid: 17841401564941304,
				// },
				{name: 'mix1023', title: 'Mix 102.3', dsgid: 4, podshowid: 9, streamstnid: 'MIX1023',
					gfkstnid: 'g_8203', gfktargdemoname: '35-49', gfktargdemoid: 1326, gfkmktid: 113,
					// fbid: 108228879221845, instaid: 17841401417340575,
					fbid: 5621826, instaid: 5621833,
				},
				{name: 'cruise1323', title: 'Cruise 1323', dsgid: 4, podshowid: 0, streamstnid: 'CRUISE 1323',
					gfkstnid: 'g_8214', gfktargdemoname: '45-59', gfktargdemoid: 458, gfkmktid: 113,
					// fbid: 108568395854166, instaid: 0,
					fbid: 5621859, instaid: 0,
				},
				{name: '96fm', title: '96FM', dsgid: 5, podshowid: 11, streamstnid: '96fm',
					gfkstnid: 'g_8905', gfktargdemoname: '45-59', gfktargdemoid: 458, gfkmktid: 114,
					// fbid: 297743720595, instaid: 17841400579097303,
					fbid: 5621857, instaid: 5621864,
				},
			],
			selectedStation: null,

			chartsSource: [],
			apexdata: {},

			streamChartsSource: {
				streamstntrnd: 'ARN Triton/AdsWizz Logs - updated monthly',
			},
			streamApexData: {},
			streamApexDataUniques: {},

			webusersweeklydata: [],
			websessweeklydata: [],
			fbimprweeklydata: [],
			instaimprweeklydata: [],

			//main demo 10+=34, F25-39=15
			gfkData: [],
			gfkCatnames: [],
			gfkSource: [],

			// fetchedGfks: 0,
			fetchedStreams: 0,
			fetchedLocal: false,
			fetchedWeb: 0,
		}
	},
	computed: {
		getChartsBtnWorking() {
			if(this.fetchedStreams === 2 && this.fetchedLocal === true && this.fetchedWeb === 4) return false;
			return true;
		},
		selectedStationObject() {
			var st = this.stationOptions.find(item => item.name === this.selectedStation);
			return st;
		},
		endOfLastMonthYmd() {
			let date = new Date()
			date.setDate(0); //remember month index starts at 0 so need to +1 below to get 9=sep eg
			let m = (date.getMonth()+1).toString()
			if(m.length === 1) m = '0'+m
			return date.getFullYear().toString() + '-' + m + '-' + date.getDate().toString()
		},
		selectedStationDemoTargetName() {
			if(this.selectedStationObject) {
				return this.selectedStationObject.gfktargdemoname;
			}
			return "";
		},
	},
	methods: {
		getData() {
			if(this.selectedStation !== "") {
				this.fetchedStreams = 0
				this.fetchedWeb = 0
				this.fetchedLocal = false
				let self = this
				let targ = "/api/brand-health-dash.php?dsgid="+this.selectedStationObject.dsgid
				targ += "&stationName="+this.selectedStationObject.name+"&podshowid="+this.selectedStationObject.podshowid
				axios.get(targ).then(function (response) {
					let ret = response.data; //response.data is returned info
					if (ret.error === 1) {
						console.log(ret);
					}
					else {
						if (ret.apexdata != null) {
							self.apexdata = ret.apexdata;
						}
						if (ret.chartsSource != null) {
							self.chartsSource = ret.chartsSource;
						}
					}
					self.fetchedLocal = true;
					self.getStreamingData('tlh'); //total hours
					self.getStreamingData('cume'); //cume/uniques
					self.getWebData('webga', 'totalUsers', 0);
					self.getWebData('webga', 'sessions', 1);
					self.getWebData('fb', 'impressions', 2);
					self.getWebData('insta', 'impressions', 3);
				}).catch(function (error) {
					console.log(error);
				});
			}
		},
		getDataAfterInitLoad() {
			this.getGFKDataAud(0,'shareToAll',48,1,[this.selectedStationObject.gfkstnid], this.selectedStationObject.gfkmktid);
			this.getGFKDataAud(1,'cume',48,1,[this.selectedStationObject.gfkstnid], this.selectedStationObject.gfkmktid);
			this.getGFKDataAud(2,'tslHrsDecimal',48,1,[this.selectedStationObject.gfkstnid], this.selectedStationObject.gfkmktid);
			this.getGFKDataAud(3,'shareToAll',this.selectedStationObject.gfktargdemoid,1, [this.selectedStationObject.gfkstnid], this.selectedStationObject.gfkmktid);
		},
		getGFKDataAud(indexid, statistic,demoid,daypart,stns,gfkmktid) {
			let self = this
			let sets = []
			let type = 'standard'
			let surveylistobs = this.getSurveyObsForMarket(gfkmktid)
			let surveylistIds = this.getSurveyIdsFromSurveyObs(surveylistobs)
			surveylistIds = surveylistIds.slice(0, 10)
			if(gfkmktid === 900) type = 'smbap'
			sets.push({
				type: type,
				dayparts: [daypart],
				demos: [demoid],
				statistics: [statistic],
				surveylist: surveylistIds,
				stationIds: stns,
				market: gfkmktid,
				audioTypeIds: [0],
			})
			this.doGetCacheDataAudology(sets).then(function(ret) {
				if(ret.error === 1) console.log(ret)
				else {
					self.gfkData[indexid] = self.prepareLineFromAudData(sets[0], ret.returnedData)
				}
			})
		},
		getStreamingData(statistic) {
			let str = "statistic="+statistic+"&stncode="+this.selectedStationObject.streamstnid+"&daypart=overall&ptype=m&grouptogether=0"
			let startday = "2022-01-01"
			let endday = this.endOfLastMonthYmd
			str += "&startdate=" + startday + "&enddate=" + endday + "&dist=all"
			let self = this
			let targ = "/api/streaming-get-gb.php?"+str
			this.chartsData = [];
			axios.get(targ).then(function (response) {
				var ret = response.data; //response.data is returned info
				if (ret.error === 1) { console.log(ret); }
				else {
					if (ret.chartsData != null) {
						if(statistic === 'tlh') {
							self.streamApexData = ret.chartsData;
						}
						else if(statistic === 'cume') {
							self.streamApexDataUniques = ret.chartsData;
						}
					}
					self.fetchedStreams++;
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
		getWebData(lookupType, mymetric, loopIndex) {
			let self = this;
			let targ = "/api/web-get-chart.php";
			let pageAssetName = '';
			let cadence = 'w'
			if(lookupType === 'webga') {
				pageAssetName = this.selectedStationObject.name;
				if(pageAssetName === 'cruise1323') pageAssetName = 'cruise';
			}
			if(lookupType === 'fb') {
				pageAssetName = this.selectedStationObject.fbid;
				lookupType = 'sprout_data_raw'
				//cadence = 'm'
			}
			if(lookupType === 'insta') {
				pageAssetName = this.selectedStationObject.instaid;
				lookupType = 'sprout_data_raw'
				//cadence = 'm'
			}
			axios.post(targ, JSON.stringify({
					type: lookupType,
					pageAsset: pageAssetName,
					metric: mymetric,
					cadence: cadence,
					since: '2023-01-01',
				})
			).then(function (response) {
				var ret = response.data; //response.data is returned info
				if(loopIndex === 0) self.webusersweeklydata = ret.data;
				if(loopIndex === 1) self.websessweeklydata = ret.data;
				if(loopIndex === 2) self.fbimprweeklydata = ret.data;
				if(loopIndex === 3) self.instaimprweeklydata = ret.data;
				self.fetchedWeb++;
			}).catch(function (error) {
				console.log(error);
			});
		},
	},
	watch: {
		selectedStation() {
			if(this.$route.path !== '/brand-health/'+this.selectedStation) {
				this.$router.push('/brand-health/' + this.selectedStation);
			}
			this.getData();
			this.getDataAfterInitLoad()
			document.title = this.selectedStationObject.title + " - Brand Health";
		},
		'store.initialObjectsReceived': {
			handler() {
				if(this.store.initialObjectsReceived === true) this.getDataAfterInitLoad()
			},
		},
	},
	mounted() {
		document.title = "Brand Health";
		let urlparams = this.$route.params;
		if(urlparams.brandname) {
			this.selectedStation = urlparams.brandname;
		}
		if(this.store.initialObjectsReceived === true) this.getDataAfterInitLoad()
	}
}
</script>

<style scoped>
.section-heading {
	margin: 20px 0;
	font-weight: bold;
	font-size: 16px;
}
</style>