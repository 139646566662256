<template>
	<div>
		<div>
			<div style="display: flex; align-items: flex-end;">
				<div style="margin-right: 10px; z-index: 1">
					<p class="above-select-label">Station</p>
					<div>
						<select class="select-styled" v-model="chosenStation">
							<option v-for="opt in stations" :key="opt.sr_id" :value="opt.sr_id">{{opt.stn_name}}</option>
						</select>
					</div>
				</div>
				<div style="margin-right: 20px; width: 200px;">
					<p class="above-select-label">Log Date</p>
					<date-picker class="dater-tr" style="width: 200px;" v-model:value="chosenDate" valueType="format" format="YYYY-MM-DD"></date-picker>
				</div>
<!--				<div><span class="primary-btn" @click="fetchData(false)">View</span></div>-->
			</div>

			<div>
				<div v-if="!isFetching" style="padding: 0 0 20px;">
					<div v-if="songplays.length === 0" style="padding: 0 10px;"><p>Nothing to show</p></div>
					<div v-else>
						<ALTable :cols="cols" :rowdata="songplays" :paginatehour="true" :excel="true" :searchbox="true"></ALTable>
					</div>
				</div>
				<div v-else style="padding: 20px 0; height: 300px; width: 500px;">
					<ChartLoaderInsert style="z-index: 9;"></ChartLoaderInsert>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {store} from '@/store.js'
import {globalMixin} from "@/mixins"
import axios from "axios";
import ALTable from "@/components/ALTable";
import DatePicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css';
import ChartLoaderInsert from "@/components/ChartLoaderInsert";

export default {
	name: "StationMusicLog",
	components: {
		ChartLoaderInsert,
		ALTable,
		DatePicker,
	},
	props: {},
	mixins: [globalMixin],
	data: function () {
		return {
			store: store,
			// viewPerms: ['elt','pcr'],
			isFetching: false,

			chosenDate: '2025-01-01',
			chosenStation: '[SYD] - WSFM',

			stations: [],
			songplays: [],

			cols: [
				// {title: 'Date', sortable: true, fldid: 'playlocal_date'},
				{title: 'Song', sortable: true, fldid: 'hourSongPosition', align: 'ctr'},
				{title: 'Time', sortable: true, fldid: 'playlocal_time', align: 'ctr'},
				{title: 'Artist', sortable: true, fldid: 'artist'},
				{title: 'Title', sortable: true, fldid: 'title'},
				{title: 'Year', sortable: true, fldid: 'year', align: 'ctr'},
				{title: 'Duration', sortable: false, fldid: 'duration', align: 'ctr'},
				{title: 'Streamers', sortable: true, fldid: 'listeners', align: 'ctr'},
				{title: 'Zetta ID', sortable: true, fldid: 'media_id', align: 'ctr'},
			],

		}
	},
	computed: {

	},
	methods: {
		getMetaLists() {
			let self = this;
			let targ = "/api/music-logs/get-meta-lists.php";
			this.isFetching = true
			axios.get(targ).then(function (response) {
				let ret = response.data //response.data is returned info
				if (ret.error === 1) console.log(ret)
				else if(ret.stations) self.stations = ret.stations
				self.isFetching = false
				self.fetchData()
			}).catch(function (error) {
				console.log(error);
				self.isFetching = false
			});
		},
		fetchData() {
			if(this.chosenStation && this.chosenDate && this.isFetching === false) {
				let self = this
				let targ = "/api/music-logs/fetch-log.php"
				this.showStationSelectOverlay = false
				this.isFetching = true
				axios.post(targ, JSON.stringify({
						logdate: self.chosenDate,
						station: self.chosenStation,
					})
				).then(function (response) {
					let ret = response.data
					if (ret.songplays) self.songplays = ret.songplays
					else self.songplays = []
					self.isFetching = false
				}).catch(function () {
					self.isFetching = false
				});
			}
		},
	},
	watch: {
		chosenDate: function () {
			this.fetchData()
		},
		chosenStation: function () {
			this.fetchData()
		}
	},
	mounted() {
		document.title = 'Station Music Logs'
		let d = new Date()
		d.setDate(d.getDate() - 2)
		this.chosenDate = d.getFullYear()+'-'+this.ldzero(d.getMonth()+1)+"-"+this.ldzero(d.getDate())
		this.getMetaLists()
	}
}
</script>

<style scoped>

</style>